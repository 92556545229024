@import './../../styles/config.scss';

.container {
  background-color: $themePrimary;
  min-height: 50px;
  padding: 0 40px;
}

.logo {
  height: 40px;
  margin-right: 40px;
}

.navLink {
  color: $white;
  padding: 8px 20px;

  &:hover {
    background-color: $white;
    color: $themePrimary;
    border-radius: 4px;
  }
}
