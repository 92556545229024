@import './../../../../styles/config.scss';

.container {
  padding: 20px 50px;
}

.projectCard {
  width: 250px;
  box-shadow: $box-shadow;
  border-radius: $border-radius;

  .image {
    width: 100%;
    height: 150px;
    background-color: #ddd;

    img {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .noImage {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    display: flex;
    height: 150px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
  }

  .content {
    padding: 10px;
  }

  .footer {
    border-top: 1px solid #ddd;

    button {
      width: 50%;
      border-radius: 0;
      text-align: center;

      &:first-child {
        border-right: 1px solid #ddd;
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
      }

      &:hover {
        background-color: $themePrimary;

        & * {
          color: $white;
        }
      }
    }
  }
}