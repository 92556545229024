@import '../../styles/config.scss';

.container {
  height: 100vh;
}

.sideLeft,
.sideRight {
  width: 50%;
  height: 100%;
}

@media (max-width: $screenBreakPoint1) {
  .sideRight {
    display: none;
  }
  .sideLeft {
    width: 100%;
  }
}
