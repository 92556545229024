.container {
  padding: 20px 50px;
  .passwordComponent {
    min-height: 350px;
    min-width: 250px;
    position: relative;
    margin-top: 36px;
    padding-top: 26px;
    padding-bottom: 50px;
    border-radius: 10px;
    z-index: 2;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.133) 1.6px 0 3.6px 0px, rgba(0, 0, 0, 0.11) 0.3px 0 0.9px 0px;
    max-width: 250px;

    .headerIcon {
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .footer {
      position: absolute;
      bottom: 0px;
      right: 0px;
      left: 0px;
      width: 100%;

      .footerButton {
        height: 50px;
        width: 100%;
        border-top: 1px solid #faf9f8;
        text-align: center;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;

        & > span {
          justify-content: center;
        }

        &:hover {
          background: #faf9f8;
        }
      }
    }
    .redirectDialog {
      padding: 10;

      .logoContainer {
        max-width: 200px;
        margin: 0px auto;
      }
    }
  }
}
